<template>
  <a class="flex-btn" v-bind="$props" v-on="$listeners">
    <span>
      <slot />
    </span>
  </a>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
