var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-right-section" },
    [
      _c("div", {
        staticClass: "custom-top",
        domProps: { innerHTML: _vm._s(_vm.customTopHtml) },
      }),
      _vm.customHtml
        ? [
            _c("div", {
              staticClass: "list",
              domProps: { innerHTML: _vm._s(_vm.customHtml) },
            }),
          ]
        : _vm.couponRegistration
        ? [
            _c("div", { staticClass: "list" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("registration.hl_redeeming_code"))),
              ]),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.list_coupon_registration1"))
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("registration.list_coupon_registration2")) +
                      " "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.list_coupon_registration3"))
                  ),
                ]),
              ]),
            ]),
          ]
        : [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("registration.hl_protection_for_all_your_needs"))
              ),
            ]),
            _c("div", { staticClass: "features" }, [
              _c("div", { staticClass: "feature" }, [
                _vm._m(0),
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("registration.hl_virus_protection"))),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("registration.virus_protection_desc"))),
                ]),
              ]),
              _c("div", { staticClass: "feature" }, [
                _vm._m(1),
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("registration.hl_browsing_protection"))),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.browsing_protection_desc"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "feature" }, [
                _vm._m(2),
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("registration.hl_family_protection"))),
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("registration.family_protection_desc"))),
                ]),
              ]),
              _c("div", { staticClass: "feature" }, [
                _vm._m(3),
                _c("h4", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.hl_ransomware_protection"))
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.ransomware_protection_desc"))
                  ),
                ]),
              ]),
              _c("div", { staticClass: "feature" }, [
                _vm._m(4),
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("registration.hl_identity_protection"))),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("registration.identity_protection_desc"))
                  ),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon-anti-virus.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon-rtpn.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon-parental-control.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon-ransomeware-protection.svg"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("img", {
        attrs: {
          src: require("../assets/images/icons/icon-identity-protection.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }